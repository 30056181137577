._2XXNs {
  height: 100%;
  flex: 2;
  position: relative;
}
._2XXNs ._2dotf {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  max-width: 100%;
  max-height: 100%;
  transition: 0.4s ease;
}
._64NRR {
  height: 100%;
  width: 100%;
}

._3nc5b {
  display: flex;
  justify-content: space-between !important;
  width: 80% !important;
  margin: 5px auto;
}

._3Jt2O {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

._20IGB {
  display: flex;
  height: 100%;
  justify-content: center;
}

._3NYKa {
  box-sizing: border-box;
  align-self: center;
  display: flex;
  flex-flow: column;
  height: 100px;
  flex: 1;
  min-height: 100%;
}

._1aRXK {
  flex: 1;
  overflow-y: auto;
}

._3jUPi {
  display: flex;
  height: 100%;
  flex-direction: column;
}

._3KjQt {
  flex-grow: 1;
}
._IiSQT {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 5000;
  flex-direction: column;
  padding: 10px 0;
  background-color: #dbdbdb;
}
._IiSQT ._1on86 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
._IiSQT ._1on86 > span {
  margin: 0 5px;
}
._IiSQT ._3lMSh {
  -webkit-animation: _10xIl 0.2s ease;
          animation: _10xIl 0.2s ease;
}
._IiSQT ._3bDQf {
  min-width: 50%;
  box-sizing: border-box;
}
@-webkit-keyframes _10xIl {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes _10xIl {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
._23ipt {
  min-width: 400px;
}
@media (max-device-width: 400px) {
  ._23ipt {
    min-width: 100%;
    max-width: 100%;
  }
}
._23ipt ._1Flql {
  position: absolute;
  right: 0;
}
._23ipt ._2lxT- {
  display: flex;
  flex-direction: row;
  min-height: 50vh;
  justify-content: center;
  align-items: stretch;
}
._23ipt ._2lxT- ._3SHuU {
  min-width: 50%;
  flex-grow: 4;
}
._23ipt ._2lxT- ._26IXk {
  min-width: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}
._23ipt ._2lxT- ._26IXk ._k4Pbp {
  margin: 0;
}
._23ipt ._2lxT- ._26IXk ._2xfm6 {
  width: 100%;
  margin-top: 10px;
}
._23ipt ._2lxT- ._26IXk ._2xfm6 button {
  width: 100%;
}
._2CUJN {
  display: flex;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 100 !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
}
._2CUJN ._dvCGL {
  top: 0;
  border-radius: 0 !important;
}
._1UdaO {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}